@import "mixin/header";
@import "mixin/navbar";
@import "mixin/content";
@import "mixin/footer";

.psk-bo-container {
    display: block;
    position: relative;
    height: 100%;
    .header {
        @extend %header;
    }

    .navbar {
        @extend %navbar;
    }

    .contents {
        @extend %content;
    }

    .footer {
        @extend %footer;
    }

    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}


