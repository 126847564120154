@import '../../mixin/responsive/responsive-list-filter';
@import '../../mixin/responsive/responsive-paging';

%responsive-content {
    @media screen and (max-device-width: 900px) {
        float: none;
        width: 100%;
        > .content-container {
            margin-top: 50px;
            padding: 0 10px 10px 10px;
            min-height: auto;

            .page-title {
                padding: 15px;
            }

            > .content {
                .contents-inner {
                    padding: 15px;

                    .detail-section {
                        .info-title {
                            padding-bottom: 10px;
                            font-size: 1.2em;
                        }
                    }
                }

                .contents-section {
                    margin-top: 15px;
                }

                .list-control-area {
                    padding: 0 10px 10px 10px;
                    .list-filter-area {
                        @extend %responsive-list-filter;
                    }

                    &.align-paging-right {
                        .paging-area {
                            position: static;
                        }
                    }

                    .paging-area {
                        @extend %responsive-paging;
                    }
                }
            }

            .common-btn-area {
                > .left-btn-section {
                    //flex: 0 0;
                }
                > .right-btn-section {
                    //flex: 0 0;
                }
                button {
                    min-width: auto;
                    height: 35px;
                }
            }
        }
    }
}
