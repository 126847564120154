@media screen and (max-device-width: 900px) {
    %responsive-list-filter {
        .range-calendar {
            margin-right: 5px;

            .p-datepicker-multiple-month {
                padding-left: 200px;
            }

            .range-date-select-list {
                position: absolute;
                padding: 20px;
                left: 10px;
                top: 10px;

                > .date-option {
                    > .btn-date-option {
                        width: 100%;
                        border-radius: 4px;
                        padding: 10px 20px;
                        color: #484848;
                        text-align: left;

                        &:hover:not(.active) {
                            background-color: #f5f5f5;
                        }

                        &.active {
                            background-color: #bc0000;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .player-multi-select {
            margin-right: 5px;
        }

        .input-event-text {
            width: 150px !important;
        }

        .p-calendar {
            .p-button {
                border-color: #ced4da;
                border-left: 0 none;
                background-color: #fff;
                color: #484848;
            }
        }

        .p-inputgroup {
            width: auto;

            .p-button {
                border-color: #ced4da;
                border-left: 0 none;
                background-color: #fff;
                color: #484848;
                white-space: nowrap;
            }
        }

        .p-button {
            padding: 0.4rem;
        }

        .button-search {
            width: auto;
            height: 30px;
        }
    }
}
