@import 'mixin/responsive/responsive-header';
@import 'mixin/responsive/responsive-navbar';
@import 'mixin/responsive/responsive-content';
@import 'mixin/responsive/responsive-footer';

@media screen and (max-width: 768px) {
    body {
        font-size: 13px;
        letter-spacing: -0.5px;
    }
}

@media screen and (max-device-width: 900px) {
    body {
        font-size: 12px;
        letter-spacing: -0.5px;
    }

    img {
        width: 100% !important;
    }

    .btn {
        box-shadow: none !important;
    }

    .p-component {
        border-radius: 3px;
    }

    .p-toast {
        width: 18rem !important;
        &.p-toast-top-right {
            top: 10px;
            right: 10px;
        }
    }

    .p-calendar {
        width: 100% !important;
        height: 35px !important;
        .p-inputtext {
            width: 100% !important;
        }
    }

    app-multiple-calendar {
        width: auto !important;
    }

    .p-buttonset {
        .p-button {
            padding: 5px;
        }
    }

    .p-datatable {
        .p-datatable-wrapper {
            overflow-x: auto;
            > table {
                width: 800px;
            }
            .p-datatable-thead {
                > tr {
                    > th {
                        padding: 5px;
                    }
                }
            }
            .p-datatable-tbody {
                > tr {
                    > td {
                        padding: 5px;
                    }
                }
            }
            .p-datatable-tfoot {
                > tr {
                    > td {
                        &.no-result {
                            padding: 25px 0;
                        }
                    }
                }
            }
        }
    }

    .p-dropdown {
        width: 100% !important;
        .p-dropdown-label {
            line-height: 150%;
            padding: 0.35rem;
        }
    }

    .p-breadcrumb {
        padding: 15px 5px 5px 5px;
    }

    p-fileUpload {
        .p-fileupload {
            .p-fileupload-buttonbar {
                padding: 10px;
                .p-button {
                    margin-right: 2px;
                    padding: 5px;
                    .pi {
                        display: none;
                    }
                }
            }
            .p-fileupload-content {
                padding: 10px;
                word-break: break-all;
            }
        }
    }
}

.ps5-bo-container {
    .header {
        @extend %responsive-header;
    }

    .navbar {
        @media screen and (max-device-width: 900px) {
            transform: translateX(105%);
            transition: transform 200ms ease-out;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
            &.panel-open {
                transform: translateX(0);
            }
        }
        @extend %responsive-navbar;
    }

    .contents {
        @extend %responsive-content;
    }

    .footer {
        @extend %responsive-footer;
    }
}
