%navbar {
    display: block;
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    width: 200px;
    background-color: #202124;
    z-index: 11;
    .nav {
        height: 100%;
        padding-top: 10px;
        overflow-y: auto;
        &.prod {
            > li {
                a {
                    &.active {
                        &::before {
                            background-color: #388e3c;
                        }
                    }
                }
            }
        }
        > li {
            a {
                position: relative;
                display: block;
                padding: 15px 15px 15px 25px;
                color: #CED4DA;
                font-size: 1.1em;
                &.active {
                    color: #fff;
                    font-weight: bold;
                    cursor: default;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 100%;
                        background-color: #BC0000;
                        top: 0;
                        left: 0;
                    }
                    &.sub {
                        &::before {
                            display: none;
                        }
                    }
                }
                &:hover:not(.active) {
                    background-color: #2F3134;
                }
            }
            > .sub-nav {
                > li {
                    > a {
                        padding: 7px 10px 7px 45px;
                        font-size: 1em;
                    }
                }
            }
        }
    }
}
