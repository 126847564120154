html,
body {
    height: 100%;
}

body {
    -webkit-text-size-adjust: 100%;
    background-color: #e0e0e0;
    color: #333;
    font-size: 14px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-family: 'RobotoRegular', sans-serif;
    word-wrap: break-word;
}

a {
    text-decoration: none;
    color: #007ce8;
}

th {
    font-weight: normal;
}

button {
    border-width: 0;
    border-style: solid;
    background-color: transparent;
    cursor: pointer;
    font-size: 1em;
    touch-action: manipulation;
}

ul,
ol {
    list-style: none;
}
em,
address {
    font-style: normal;
}
strong {
    font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

table {
    border-collapse: collapse;
}
pre {
    font-family: 'Courier', serif;
    line-height: 150%;
}
fieldset {
    border: 0 none;
}

.hidden {
    display: none !important;
}
.block {
    display: block !important;
}
.inline-block {
    display: inline-block !important;
}
.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.vt {
    vertical-align: top !important;
}
.vm {
    vertical-align: middle !important;
}
.vb {
    vertical-align: bottom !important;
}
.nowrap {
    white-space: nowrap !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}

.stakers-note {
    em {
        font-style: italic !important;
    }
    strong {
        font-weight: bold !important;
    }
    u {
        text-decoration: underline !important;
    }
    a {
        &:hover {
            text-decoration: underline !important;
        }
    }
}
