%paging{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-top: 5px;
    .paging-select-dropdown {
        display: inline-block;
        border: 0 none;
        margin-right: 10px;
    }
    .paging-wrap {
        display: flex;
        align-items: center;
    }
    .paging-status {
        padding-right: 10px;
        > .current {
            color: #484848;
            font-family: "RobotoBold", sans-serif;
        }
        > .total {
            color: #A6ADB4;
        }
    }
    .p-buttonset {
        display: flex;
    }
    .btn-paging {
        display: block;
        color: #4E5056;
        background-color: #fff;
        border: 1px solid #ced4da;
        margin-left: -1px;
        &.current-page {
            width: 38px;
            padding: 0.5rem;
            text-align: center;
        }
    }
}
