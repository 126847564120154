%responsive-navbar {
    @media screen and (max-device-width: 900px) {
        top: 50px;
        left: auto;
        right: 0;
        .nav {
            padding-top: 0;
            overflow-y: auto;
            height: 100%;

            > li {
                a {
                    padding: 15px;
                }

                > .sub-nav {
                    > li {
                        > a {
                            padding-left: 25px;
                        }
                    }
                }
            }
        }
    }
}
