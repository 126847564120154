.p-button {
    border-radius: 6px;
    background-color: #BC0000;
    border-color: #BC0000;
    &:enabled{
        &:hover {
            background-color: #CF0000;
            border-color: #CF0000;
        }
    }
    &.p-button-danger {
        background-color: #BC0000;
        color: #fff;
    }
    &.button-reset {
        background-color: transparent;
        border-color: transparent;
        color: #A6ADB4;
        font-size: 1em;
        &:enabled{
            &:hover {
                background-color: transparent;
                border-color: transparent;
                color: #333;
            }
        }
    }
    &.button-download {
        background-color: transparent;
        border-color: #9d9d9d;
        border-radius: 3px;
        margin-right: 10px;
        padding: 0.5rem 0.7rem !important;
        color: #9d9d9d;
        font-size: 1em;
        &:enabled {
            &:hover {
                background-color: transparent;
                border-color: #333;
                color: #333;
            }
        }
    }
    &.p-button-secondary {
        background-color: #A6ADB4;
        border-color: #A6ADB4;
        &:enabled {
            &:hover {
                background-color: #AFB6BD;
                border-color: #AFB6BD;
            }
        }
    }
}

.p-dialog {
    &.p-confirm-dialog {
        .p-confirm-dialog-message {
            margin-left: 0;
        }
    }
    .p-dialog-header {
        .p-dialog-title {
            font-weight: normal;
            font-family: 'RobotoBold', sans-serif;
            color: #6c757d;
        }
    }
    .p-dialog-footer {
        .p-confirm-dialog-reject {
            background-color: #a6adb4;
            border-color: #a6adb4;
            &:enabled{
                &:hover {
                    background-color: #AFB6BD;
                    border-color: #AFB6BD;
                }
            }
        }
    }
}

.p-inputtext, .p-component {
    font-size: 1em;
}
.p-accordion-tab-active{
    .p-accordion .p-toggleable-content {
        overflow: inherit;
    }
}
.p-fileupload-choose:not(.p-disabled):hover {
    background-color: #CF0000;
    border-color: #CF0000;
}

.p-fileupload-row {
    > div {
        width: auto !important;
    }
}

p-editor {
    display: block;
    position: relative;
    z-index: 20;
}

.p-datatable {
    .p-datatable-thead {
        > tr {
            > th {
                font-family: 'RobotoBlack', sans-serif;
                text-align: center;
                > .pi {
                    color: #007CE8;
                    vertical-align: middle;
                    &.info {
                        position: relative;
                        color: #707070;
                        padding: 0 5px;
                        cursor: pointer;
                        &:hover {
                            > .info-layer {
                                display: block;
                            }
                        }
                        > .info-layer {
                            display: none;
                            position: absolute;
                            min-width: 170px;
                            border: 1px solid #D95555;
                            background-color: #FFF5F5;
                            border-radius: 4px;
                            padding: 10px;
                            color: #C31919;
                            font-size: 0.75em;
                            line-height: 160%;
                            top: 25px;
                            left: 50%;
                            transform: translate(-50%, 0);
                            z-index: 10;
                            &::before, &::after {
                                content: '';
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                border: 7px solid transparent;
                            }
                            &::before {
                                top: -15px;
                                border-bottom-color: #D95555;
                            }
                            &::after {
                                top: -14px;
                                border-bottom-color: #FFF5F5;
                            }
                        }
                    }
                }
                > .sortable {
                    position: relative;
                    display: inline-block;
                    height: 25px;
                    vertical-align: middle;
                    &::before {
                        content: '';
                        position: absolute;
                        border: 5px solid transparent;
                        border-bottom: 5px solid #CED4DA;
                        top: 0;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        border: 5px solid transparent;
                        border-top: 5px solid #CED4DA;
                        bottom: 0;
                    }
                }
            }
        }
    }
    .p-datatable-tbody {
        > tr {
            > td {
                .amount {
                    font-family: 'RobotoBlack', sans-serif;
                    font-size: 1.28em;
                    &.plus {
                        color: #34B200;
                    }
                }
                .exchange {
                    color: #484848;
                }
                .label-container {
                    display: block;
                    .label {
                        display: inline-block;
                        width: 105px;
                        padding: 2px 10px 2px 0;
                        border-radius: 3px;
                        text-transform: uppercase;
                        &.label-sm {
                            width: 70px;
                        }
                    }
                    .value {
                        font-family: 'RobotoBlack', sans-serif;
                        color: #222;
                    }
                }
                .status {
                    display: inline-block;
                    padding: 4px 8px;
                    background-color: #E3E7E9;
                    border-radius: 4px;
                    color: #A6ADB4;
                    font-weight: bold;
                    &.working {
                        background-color: #ECF9E0;
                        color: #34B200;
                    }
                }
                .status-normal {
                    display: inline-block;
                    min-width: 85px;
                    padding: 7px 5px;
                    background-color: #F4F6F7;
                    border-radius: 4px;
                    text-align: center;
                    color: #484848;
                }
                .transaction-status {
                    display: inline-block;
                    padding: 2px 4px;
                    background-color: #E3E7E9;
                    border-radius: 4px;
                    color: #A6ADB4;
                    font-size: 0.78em;
                    font-weight: bold;
                    &.pending {
                        background-color: #FFEEBF;
                        color: #CEA443;
                    }
                    &.approved {
                        background-color: #ECF9E0;
                        color: #34B200;
                    }
                }
                .btn-status {
                    width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    &.deactivate {
                        color: #C31919;
                        &:hover {
                            background-color: #F5DADA;
                        }
                    }
                    &.activate {
                        color: #34B200;
                        &:hover {
                            background-color: #ECF9E0;
                        }
                    }
                }
                .seller {
                    display: block;
                    padding-bottom: 5px;
                    color: #484848;
                    font-family: 'RobotoBlack', sans-serif;
                    font-size: 0.93em;
                }
            }
        }
    }
    .p-datatable-tfoot {
        > tr {
            &.total {
                > td {
                    background: #FFF2F2;
                    color: #484848;
                    font-family: 'RobotoBold', sans-serif;
                }
            }
            > td {
                &.no-result {
                    padding: 50px 0 !important;
                    background-color: #fff !important;
                    text-align: center !important;
                }
            }
        }
    }
}

.table-group {
    display: flex;
}

.table-row {
    width: 100%;
    border-radius: 6px;
    > caption {
        background-color: #484848;
        border-radius: 4px 4px 0 0;
        color: #fff;
        padding: 10px 10px 10px 15px;
        text-align: left;
    }
    > tr {
        > th, > td {
            padding: 15px;
            border: 1px solid #CED4DA;
            text-align: left;
            color: #484848;
        }
        > th {
            background-color: #F4F6F7;
        }
        > td {
            background-color: #fff;
            font-family: 'RobotoBlack', sans-serif;
            &.red {
                color: #E20D0D;
            }
            .normal {
                font-family: 'RobotoRegular', sans-serif;
            }
            .percentage {
                color: #484848;
                font-family: 'RobotoBlack', sans-serif;
                font-size: 1.07em;
            }
            .value {
                font-family: 'RobotoBlack', sans-serif;
            }
            .exchange {
                font-size: 1.07em;
                padding-left: 5px;
            }
        }
    }
}

.table-detail {
    width: 100%;
    border: 1px solid #CED4DA;
    border-radius: 10px;
    > tr {
        > th, > td {
            padding: 7px;
            color: #484848;
            text-align: center;
        }
        > th {
            background-color: #484848;
            color: #fff;
        }
        > td {
            background-color: #fff;
            font-family: 'RobotoBlack', sans-serif;
        }
    }
}

.button-adjust {
    width: 26px;
    height: 26px;
    color: #fff;
    border-radius: 50%;
    vertical-align: middle;
    &.button-plus {
        background-color: #4CC21C;
        margin-right: 5px;
    }
    &.button-minus {
        background-color: #DE6161;
    }
}

.p-toast {
    .p-toast-message {
        background-color: #fff !important;
        color: #333 !important;
    }
}

.p-dropdown {
    &:not(.p-disabled) {
        &.p-focus {}
    }
}

.p-selectbutton {
    .p-button {
        &:focus {
            box-shadow: none;
        }
        &.p-highlight {
            background: #EF2E2E;
            border-color: #EF2E2E;
            &:hover {
                background: #EF2E2E;
                border-color: #EF2E2E;
            }
        }
    }
}

.p-inline-message {
    .p-inline-message-text {
        font-size: 0.9em;
    }
}

@media screen and (max-device-width: 900px) {
    .p-datatable {
        .p-datatable-thead {
            > tr {
                > th {
                    > .pi {
                        &.info {
                            > .info-layer {
                                min-width: auto;
                            }
                        }
                    }
                }
            }
        }
        .p-datatable-tbody {
            > tr {
                > td {
                    .label-container {
                        .label {
                            display: inline-block;
                            width: 105px;
                            padding: 2px 10px 2px 0;
                            border-radius: 3px;
                            text-transform: uppercase;
                            &.label-sm {
                                width: 70px;
                            }
                        }
                    }
                    .status {
                        padding: 4px;
                    }
                    .status-normal {
                        min-width: auto;
                    }
                }
            }
        }
        .p-datatable-tfoot {
            > tr {
                > td {
                    &.no-result {
                        padding: 25px 0;
                    }
                }
            }
        }
    }

    .table-row {
        > caption {
            padding: 10px 10px 10px 15px;
        }
        > tr {
            > th, > td {
                padding: 5px;
            }
        }
    }
}

.p-inputswitch {
    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background-color: #bc0000;
        }
        &:not(.p-disabled) {
            &:hover {
                .p-inputswitch-slider {
                    background-color: darken(#bc0000, 5%);
                }
            }
        }
    }
}
