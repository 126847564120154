@import '../mixin/list-filter';
@import '../mixin/paging';

%content {
    position: relative;
    float: right;
    width: calc(100% - 200px);
    height: 100%;
    > .content-container {
        margin-top: 60px;
        padding: 30px;
        min-height: calc(100vh - 111px);
        .page-title {
            border-radius: 10px 10px 0 0;
            background-color: #f6f8f9;
            padding: 20px;
            color: #484848;
            font-size: 1.4em;
            font-weight: normal;
        }
        > .content {
            position: relative;
            border-radius: 0 0 10px 10px;
            .contents-inner {
                position: relative;
                padding: 30px;
                background-color: #fff;
                .detail-section {
                    .info-title {
                        padding-bottom: 20px;
                        font-size: 1.4em;
                        font-family: 'RobotoBold', sans-serif;
                        font-weight: normal;
                        > em {
                            font-weight: normal;
                            font-family: 'Roboto Light', sans-serif;
                        }
                    }
                }
            }
            .contents-section {
                margin-top: 20px;
                border-radius: 10px;
                border-top: 1px solid #ddd;
                background-color: #f6f8f9;
                padding-bottom: 10px;
                .common-btn-area {
                    padding-bottom: 0;
                    background-color: transparent;
                    border: 0 none;
                }
            }
            .list-control-area {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 20px 15px 20px;
                background-color: #f6f8f9;
                border-bottom: 1px solid #ddd;
                &.edit-tournament {
                    background-color: transparent;
                    .paging-area {
                        position: absolute;
                        top: 25px;
                        right: 15px;
                    }
                }
                &.align-paging-right {
                    padding-top: 0;
                    .paging-area {
                        position: absolute;
                        top: -35px;
                        right: 20px;
                        z-index: 10;
                    }
                }
                .list-filter-area {
                    @extend %list-filter;
                }
            }
            .paging-area {
                @extend %paging;
            }
        }
        .common-btn-area {
            display: flex;
            border-radius: 0 0 10px 10px;
            border-top: 1px solid #ddd;
            background-color: #f6f8f9;
            padding: 20px;
            > .left-btn-section {
                flex: 1 0;
                button {
                    min-width: 100px;
                    height: 48px;
                    margin-right: 10px;
                }
            }
            > .right-btn-section {
                display: flex;
                justify-content: flex-end;
                flex: 1 0;
                > button {
                    min-width: 100px;
                    padding: 15px;
                    margin-left: 5px;
                }
            }
        }
    }
}
