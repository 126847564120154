%list-filter {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    .range-calendar {
        margin-right: 5px;
        .p-datepicker-multiple-month {
            padding-left: 200px;
        }
        .range-date-select-list {
            position: absolute;
            padding: 20px;
            left: 10px;
            top: 10px;
            > .date-option {
                > .btn-date-option {
                    width: 100%;
                    border-radius: 4px;
                    padding: 10px 20px;
                    color: #484848;
                    text-align: left;
                    &:hover:not(.active) {
                        background-color: #f5f5f5;
                    }
                    &.active {
                        background-color: #BC0000;
                        color: #fff;
                    }
                }
            }
        }
    }
    .player-multi-select {
        margin-right: 5px;
    }
    .input-event-text {
        margin-right: 5px;
    }
    .p-calendar {
        .p-button {
            border-color: #ced4da;
            border-left: 0 none;
            background-color: #fff;
            color: #484848;
        }
    }
    .p-inputgroup {
        width: auto;
        .p-button {
            border-color: #ced4da;
            border-left: 0 none;
            background-color: #fff;
            color: #484848;
            white-space: nowrap;
        }
    }
    .p-button {
        white-space: nowrap;
    }
    .button-search {
        width: 150px;
        height: 35px;
    }
    .total-balance-area {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .label {
            padding-right: 10px;
        }
        .value {
            padding-right: 10px;
            color: #222;
            font-family: 'RobotoBlack', sans-serif;
        }
    }
}
