@media screen and (max-device-width: 900px) {
    %responsive-paging {
        flex-wrap: wrap;
        .paging-select-dropdown {
            margin-right: 5px;
        }

        .paging-status {
            padding-right: 5px;
        }

        .p-buttonset {
            display: flex;
        }

        .btn-paging {
            width: 30px;
            height: 30px;

            &.current-page {
                width: 30px;
            }
        }
    }
}
