%responsive-header {
    @media screen and (max-device-width: 900px) {
        position: fixed;
        width: 100%;
        height: 50px;
        left: 0;
        top: 0;
        .heading-title {
            padding-left: 10px;
            font-size: 1.8em;
            .logo {
                padding-right: 5px;
                img {
                    width: 100%;
                    height: 17px;
                }
            }
            .admin-title {
                display: none;
            }
        }
        .header-admin {
            display: flex;
            padding-right: 10px;

            .admin-name {
                padding: 5px;
            }

            .button-menu {
                display: block;
                margin-left: 5px;

                .pi {
                    color: #fff;
                }
            }
        }
    }
}
