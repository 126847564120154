%header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 80px;
    background-color: #bc0000;
    padding: 5px 0;
    color: #fff;
    left: 0;
    top: 0;
    z-index: 100;
    &.prod {
        background-color: #388e3c;
    }
    .heading-title {
        padding-left: 20px;
        font-size: 1.8em;
        .logo {
            display: inline-flex;
            align-items: center;
            padding-right: 10px;
            vertical-align: middle;
            img {
                height: 26px;
            }
        }
        .admin-title {
            padding-left: 10px;
            border-left: 1px solid rgba(#fff, 0.3);
            vertical-align: middle;
            font-size: 0.85em;
            font-family: 'RobotoBlack', sans-serif;
            > strong {
                font-family: 'RobotoBlack', sans-serif;
            }
        }
    }
    .header-admin {
        padding-right: 15px;
        .admin-name {
            display: block;
            cursor: pointer;
            padding: 10px;
        }
        .pi {
            vertical-align: middle;
        }
        .button-menu {
            display: none;
        }
    }
}
