@font-face {
    font-family: 'RobotoMedium';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoBold';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoBlack';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoBlackItalic';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-black-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoLight';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoThin';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RobotoThinItalic';
    src: url('https://cdn.pokerstake.com/staking/common/font/roboto/roboto-thin-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
